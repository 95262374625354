import type { WithdrawalData } from '../interfaces'

export const useWithdrawalStore = defineStore('withdrawal', () => {
  const data = ref<WithdrawalData | undefined>()

  function setWithdrawalData(dataValue: WithdrawalData) {
    data.value = dataValue
  }

  function clearWithdrawalData() {
    data.value = undefined
  }

  const withdrawalData = computed(() => data.value)

  return {
    withdrawalData,
    setWithdrawalData,
    clearWithdrawalData,
  }
})
